<template>
  <v-card flat color="#00000000">
    <v-container>
      <v-layout row wrap>
        <v-flex xs12 class="stripe-title mb-2">
          Apple Pay
          <span class="ml-2 red--text">
            {{ stripeError }}
          </span>
        </v-flex>
        <!-- <v-flex xs12>
          <v-text-field id="card-name-element" class="square-fields" maxlength="128" :disabled="disabled" v-model="name" placeholder="Name on card" flat solo />
        </v-flex> -->
        <v-flex xs12 id="applepay-button-container" class="mb-2">
          <button id="payment-request-button"></button>
        </v-flex>
      </v-layout>
    </v-container>
    <v-card-actions>
      <v-spacer/>
      <v-btn tile depressed :height="64" :width="64" :disabled="disabled" @click="back()">
        <v-icon :size="48">mdi-chevron-left</v-icon>
      </v-btn>
      <!-- <v-btn tile depressed :height="64" :width="128" class="next-button" color="inherit" :disabled="disabled || !canNext" :loading="loading" @click="next()">
        Pay
      </v-btn> -->
      <v-spacer/>
    </v-card-actions>
  </v-card>
</template>
<script>
// import { loadStripe } from '@stripe/stripe-js';
export default {
  name: 'ApplePay',
  props: ['plan', 'preSubscribeMethod'],
  components: {
  },
  data: () => ({
    disabled: false,
    loading: false,
    paymentCreated: false,
    name: '',
    stripe: null,
    cardElement: null,
    stripeError: ''
  }),
  computed: {
    detailsValid () {
      if (this.name != '') {
        return true;
      }
      return false;
    },
    canNext () {
      return this.detailsValid;
    }
  },
  watch: {
  },
  methods: {
    next: function () {
      let scope = this;
      scope.disabled = true;
      scope.loading = true;

      if(!scope.paymentCreated) {
        // create the stripe payment
        scope.stripe.createPaymentMethod({
          type: 'card',
          card: scope.cardElement,
          billing_details: {
            name: scope.name,
          }
        }).then(function (res) {
          if (res.error) {
            console.log(res.error.message);
            scope.stripeError = res.error.message;
            scope.disabled = false;
            scope.loading = false;
          } else {
            scope.stripeError = '';

            // register the user
            scope.preSubscribeMethod().then(function () {
              let paymentMethod = res.paymentMethod;

              let subData = {
                planType: scope.plan.selectedPlan.planType,
                paymentType: 'stripe',
                planId: '',
                paymentKey: paymentMethod.id
              }

              scope.$store.dispatch('subscribe', subData).then(function (subRes) {
                if (subRes.valid) {
                  // subscribed successfully
                  scope.$emit('finish')
                } else {
                  // something went wrong
                  scope.$emit('reset')
                }
              })
            })
          }
        })
      }
    },
    back: function () {
      this.$emit('back');
    },
    numberFocused: function () {
      let inputParent = this.$refs['number-field'].$el;
      let input = inputParent.getElementsByTagName('input')[0];
      input.focus();
    },
    setupStripeApplePay: function () {
      // eslint-disable-next-line
      this.stripe = Stripe(process.env.VUE_APP_STRIPE_KEY);
      
      var paymentRequest = this.stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Demo total',
          amount: 1099,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });
 
      const elements = this.stripe.elements();

      var prButton = elements.create('paymentRequestButton', {
        paymentRequest: paymentRequest,
      });

      paymentRequest.canMakePayment().then(function(result) {
        if (result) {
          prButton.mount('#payment-request-button');
        } else {
          document.getElementById('payment-request-button').style.display = 'none';
        }
      });
    }
  },
  mounted () {
    this.setupStripeApplePay();
  }
}
</script>
<style lang="scss" scoped>
.stripe-title {
}
.hidden-number-field {
  height: 0px !important;
  overflow: hidden;
}
#card-element {
  background-color: #282828;
  padding: 16px;
}
</style>
