<template>
  <v-container class="register pa-0" fluid>
    <v-container v-if="loading" class="main-register-panel" fill-height fluid>
      <v-row align="center" justify="center">
        <v-progress-circular
          class="ma-6"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-row>
    </v-container>
    <div v-else-if="!completed" class="main-register-panel">
      <v-img @click="navigate('/')" class="disrupt-logo" :src="'./icons/logo-d+.png'" :height="32" :max-width="200" contain />
      <v-container fill-height fluid>
        <v-row class="mt-4" align="center" justify="center">
          <v-col cols="12" md="6" lg="4">
            <plans :plan="plan" v-show="sequence == 0" :upgrading="true" @next="next" @back="back"/>
            <payment :payment="payment" :plan="plan" v-show="sequence == 1 && !isCoupon" @next="next" @back="back"/>
            <coupon :plan="plan" v-if="sequence == 1 && isCoupon" @back="back"  :preSubscribeMethod="preUpgrade" @finish="completeUpgrade"/>
            <paypal v-if="sequence == 2 && payment.selectedPayment.key == 'paypal'" :plan="plan" @next="next" @back="back" :preSubscribeMethod="preUpgrade" @finish="completeUpgrade"/>
            <stripe v-if="sequence == 2 && payment.selectedPayment.key == 'stripe'" :plan="plan" @next="next" @back="back" :preSubscribeMethod="preUpgrade" @finish="completeUpgrade"/>
            <applepay v-if="sequence == 2 && payment.selectedPayment.key == 'applepay'" :plan="plan" @next="next" @back="back" :preSubscribeMethod="preUpgrade" @finish="completeUpgrade"/>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div v-else class="finished-register-panel">
      <v-img @click="navigate('/')" class="disrupt-logo" :src="'./icons/logo-d+.png'" :height="32" :max-width="200" contain />
      <v-container fill-height fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" md="4" class="text-center">
            <div class="thank-you-text">Thank you!</div>
            <div class="thank-you-subtext">Traveling to <span class="primary--text">new content...</span></div>
            <v-progress-circular
              class="ma-6"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-toolbar dark flat color="rgba(0,0,0,0)" width="100%" height="80" class="register-footer">
      <v-icon v-if="!completed" x-large>mdi-lock</v-icon>
      <v-icon v-else x-large>mdi-lock-open-variant</v-icon>
      <v-spacer/>
      <div class="steps" v-if="!completed">
        <v-flex class="text-center">
          Step {{sequence+1}}
        </v-flex>
        <v-flex>
          <v-icon :color="sequence == 0 ? 'primary': 'none'">mdi-checkbox-blank-circle</v-icon>
          <v-icon :color="sequence == 1 ? 'primary': 'none'">mdi-checkbox-blank-circle</v-icon>
          <v-icon :color="sequence == 2 ? 'primary': 'none'">mdi-checkbox-blank-circle</v-icon>
        </v-flex>
      </div>
    </v-toolbar>
  </v-container>
</template>
<script>
import plans from '../components/register/plans'
import payment from '../components/register/payment'

import paypal from '../components/register/paypal'
import stripe from '../components/register/stripe'
import applepay from '../components/register/applepay'
import coupon  from '../components/register/coupon'

export default {
  name: 'Register',
  components: {
    plans,
    payment,
    paypal,
    stripe,
    applepay,
    coupon
  },
  data: () => ({
    loading: true,
    sequence: 0,
    completed: false,
    confirmed: false,
    plan: {
      selectedPlan: null
    },
    payment: {
      selectedPayment: { key: 'stripe'}
    },
    paymentDetails: {
      planType: null,
      paymentType: null
    }
  }),
  computed: {
    completeFlag () {
      return this.$route.query.complete
    },
    canNext () {
      if (this.sequence == 0) {
        if (this.userData.username != '' && this.userData.password != '' && this.userData.email != '') {
          return this.$refs.user.isUsernameValid && this.$refs.user.isPasswordValid && this.$refs.user.isEmailValid;
        }
      } else if (this.sequence == 1) {
        if (this.plan.selectedPlan != null) {
          return true;
        }
      } else if (this.sequence == 2) {
        if (this.payment.selectedPayment != null) {
          return true;
        }
      }
      return false;
    },
    nextText () {
      if (this.sequence == 2 || this.sequence == 3) {
        return 'Pay ' + this.plan.selectedPlan.currency + this.plan.selectedPlan.price;
      }
      return 'Next';
    },
    nextColor () {
      if (this.sequence == 2) {
        return 'primary';
      }
      return 'inherit';
    },
    isCoupon () {
      if (this.plan.selectedPlan) {
        return this.plan.selectedPlan.title == 'Coupon'
      }
      return false;
    },
    currentUser () {
      return this.$store.getters.getUser;
    },
    activeSubscription () {
      if (this.currentUser && this.currentUser.subscriptions) {
        return this.currentUser.subscriptions.find(s => s.active);
      }
      return null;
    },
    premium () {
      if (this.activeSubscription && this.activeSubscription.premium) {
        return true;
      }
      return false;
    },
  },
  methods: {
    navigate: function (path) {
      this.$router.push(path).catch(()=>{});
    },
    back: function () {
      if (this.sequence > 0) {
        this.sequence--;
      } else {
        this.navigate('/account');
      }
    },
    next: function () {
      if (this.sequence < 3) {
        this.sequence++;
      }
    },
    preUpgrade: function () {
      return new Promise((resolve) => {
        resolve(true);
      })
    },
    completeUpgrade: function () {
      let self = this;

      self.completed = true;
      setTimeout(() => {
        self.$store.dispatch('getUser');
        self.$store.commit('setIsLoggedIn', true);
        self.navigate('/');
      }, 3000);
    },
    checkCanUpgrade: function () {
      if (this.currentUser) {
        if (this.currentUser.role == 'Investor') {
          return false;
        }
        if (this.premium) {
          return false;
        }
      }
      return true;
    }
  },
  mounted () {
    if(!this.checkCanUpgrade()) {
      this.navigate('/account');
    } else {
      this.loading = false;
    }

    if (this.completeFlag) {
      this.completeUpgrade();
    }
  }
}
</script>
<style lang="scss">
.register {
  background-image: url('/backgrounds/register-optimized.png');
  height: 100vh;
  background-size: cover;
  background-position: left;
  background-blend-mode: multiply;
  background-color: #848484;
}
.register-footer {
  backdrop-filter: blur(4px);
}
.main-register-panel {
  background-color: #0f0f0f;
  height: calc(100vh - 80px);
  overflow-y: auto;
}
.finished-register-panel {
  height: calc(100vh - 80px);
}
.disrupt-logo {
  // position: absolute;
  top: 16px;
  left: 16px;
}
.register-title {
  font-size: 32px;
  justify-content: center;
}
.next-button .v-btn__content {
  font-size: 28px !important;
  text-transform: none;
  letter-spacing: 0px !important;
}
.thank-you-text {
  font-size: 80px;
}
.thank-you-subtext{
  font-size: 25px;
  letter-spacing: 0px;
}
</style>